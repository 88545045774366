import React, {useCallback} from "react";
import * as XLSX from "xlsx";
import { Stack, Box, Button, Grid, LinearProgress, Typography, CircularProgress, Dialog, DialogContent, DialogContentText } from "@mui/material";
import { areArraysEquals } from "../../helper/arrays";
import { useDropzone } from "react-dropzone";
import { getFileType } from "../../../utils/formatValues";
import { UploadFile } from "../UploadFile";

export function UploadExcel({
  handleUploadExcel,
  className = "",
  placeholder = "Subir",
  replaceHeaders,
  validateHeaders,
  onErrorHeaders,
  handleSendExcel,
  maxLength,
  isOpen,
  onClose,
}) {
  const [progress, setProgress] = React.useState(0);
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader()
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        let sheet = workbook.Sheets[sheetName];
        if (replaceHeaders || validateHeaders) {
          const headersExcel = XLSX.utils.sheet_to_json(
            sheet,
            { header: 1 },
          );
          if (validateHeaders && !areArraysEquals(headersExcel[0], validateHeaders)) {
            setProgress(0);
            return onErrorHeaders({
              title: 'El archivo cargado tiene un formato inválido',
              messages: 'Debes utilizar el archivo proporcionado', 
            });
          }
          if (replaceHeaders) {
            headersExcel[0] = replaceHeaders;
            sheet = XLSX.utils.aoa_to_sheet(headersExcel);
            workbook.Sheets[sheetName] = sheet;
          }
        }
        const dataExcel = XLSX.utils.sheet_to_json(sheet, { blankRows: false });
        if (maxLength && dataExcel.length !== maxLength) {
          setProgress(0);
          return onErrorHeaders({
            title: 'El archivo supera la cantidad de solicitudes pendientes de aprobación existentes',
            messages: `Este archivo tiene ${dataExcel.length} solicitudes`,
          });
        }
        dataExcel.forEach((data) => {
          if (!data.approved) {
            setProgress(0);
            return onErrorHeaders({
              title: 'El archivo cargado viene vacío',
              messages: `Por favor carga un archivo con todos sus campos completos`,
            });
          }
        });
        handleUploadExcel(dataExcel);
      }
      reader.addEventListener('progress', (e) => {
        if(e.lengthComputable) {
          setProgress((e.loaded / e.total) * 100)
        }
      })
      reader.readAsArrayBuffer(file)
    })
    
  }, [])

  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: [getFileType('.xlsx')],
    multiple: false,
    onDrop,
  })

  const LinearProgressWithLabel = (props) => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {`${Math.round(props.value)}%`}
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Stack className={className}>
      <UploadFile
        typeFile='text/xlsx'
        acceptedFiles={acceptedFiles}
        fileRejections={fileRejections}
        getRootProps={getRootProps}
        getInputProps={getInputProps}
        isDragAccept={isDragAccept}
        isDragReject={isDragReject}
      />
      {progress > 0 && 
        <Box sx={{paddingLeft:'40%', paddingRight:'38%'}}>
          <LinearProgressWithLabel value={progress} />
        </Box>
      }
      <Grid container justifyContent="flex-end">
        <Button
          variant="contained"
          component="label"
          size="medium"
          color="secondary"
          onClick={() => handleSendExcel()}
          disabled={progress === 0}
        >
          {placeholder}
        </Button>
      </Grid>
      <Dialog
        open={isOpen}
        onClose={onClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        sx={{ '& .MuiPaper-root': { px: 5, py: 10, borderRadius: '16px' } }}
      >
        <Stack sx={{  alignItems:'center'}}>
          <CircularProgress color='primary' size={50} />
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
            <Typography fontFamily={'Montserrat'} fontWeight={700} fontSize={"16px"} lineHeight={"19.5px"} variant="body1" color="#000000">
              Estamos procesando el archivo cargado
            </Typography>
            </DialogContentText>
          </DialogContent>
        </Stack>
      </Dialog>
    </Stack>
  );
}