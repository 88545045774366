import React, { useState } from "react";
import {
  Stack,
  Typography,
  Button,
  Divider,
  Box,
} from "@mui/material";
import { PaperWhite } from "../../../components/Papers";
import { ReactComponent as DownloadIcon } from "../../assets/images/download.svg"
import { LoadingSpinner } from "../../../components/LoadingSpinner";
import { UploadExcel } from "../../components/Excel/UploadExcel";
import { MessageAlert } from "../../components/MessageAlert";
import { Alert } from "../../../components/Alert";
import { UploadFinish } from "./applications/uploadFinish";
import { useReportAllEmployee } from "../../services/hooks/Security/useReportAllEmployee";
import { urlDownload } from "../../helper/files";

const initialAlertError = {
  title: "",
  open: false,
  messages: [],
  icon: "",
  buttonClose: true,
};

export default function SecurityApplicationsMassive() {
  const [, setDataToUpload] = useState();
  const [uploadFinish, setUploadFinish] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [alert, setAlert] = useState(initialAlertError);
  const { data: dataAllEmployee, isLoading: isLoadingDataAllEmployee } = useReportAllEmployee();

  const handleDownloadReport = () => {
    urlDownload(dataAllEmployee.fileUrl);
  }

  const handleSendData = () => {
    setIsOpen(true);
    setUploadFinish(true);
  }

  const handleUploadExcel = (data) => {
    setDataToUpload(data);
  };
  const onErrorHeaders = (dataError) => {
    setAlert({
      ...alert,
      open: true,
      title: dataError.title,
      messages: [dataError.messages],
    });
  }

  const handleConfirmationAlert = () => {
    setAlert({
      ...alert,
      open: false,
    });
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  if (uploadFinish) {
    return (
      <Stack spacing={4}>
        <Typography variant="h4" align="center">
          {`Postulaciones`}
        </Typography>
        <UploadFinish
          goBack={() => setUploadFinish(false)}
          goBackTitleButton={`Volver a Carga masiva`}
        />
      </Stack>
    );
  }

  if (isLoadingDataAllEmployee) return <LoadingSpinner open />;

  return (
    <Stack spacing={2}>
      <Typography variant="h4" align="center">
        Postulaciones
      </Typography>
      <PaperWhite direction="row" spacing={2}>
        <Stack spacing={2} sx={{mb:2}}>
          <Typography variant="h1" fontFamily={"Montserrat"} fontWeight={700} fontSize={"18px"} lineHeight={"21.94px"}>
            Aprobación de solicitudes de Conductores, Peonetas y Proveedores 
          </Typography>
          <Typography variant="body1" fontFamily={"Lato"} fontWeight={800} fontSize={"16px"} lineHeight={"21.6px"}>
            {`Tienes ${dataAllEmployee?.metadata?.total_courier} solicitudes de Conductores,
             ${dataAllEmployee?.metadata?.total_peoneta} de Peonetas y
             ${dataAllEmployee?.metadata?.total_provider} de Proveedores pendientes de aprobación.`}
          </Typography>
          <Typography variant="body1" fontFamily={"Lato"} fontWeight={400} fontSize={"14px"} lineHeight={"18.9px"} >
            1. Descarga el archivo<br/>
            2. Procesa el archivo en la plataforma SubContrataLey<br/>
            3. Sube el archivo con los resultados obtenidos.<br/>
          </Typography>
        </Stack>
        <Stack spacing={2}>
          <Button 
              variant='outlined'
              startIcon={<DownloadIcon />}
              onClick={handleDownloadReport}
              sx={{
                border:"1.5px solid #FF7E44", 
                borderRadius:"12px", 
                width:"200px", 
                height:"40px",
                padding:"10px, 16px, 10px, 16px"
              }}>
                {"Descargar Archivo"}
            </Button>
        </Stack>
        <Divider sx={{ pt: 1, pb: 2, mb: 3 }} />
        <MessageAlert 
          message={
            <Box sx={{display:"flex"}}>
              <Typography>
                <b>Este archivo tiene {dataAllEmployee.metadata.total_employee} solicitudes. </b>
                Asegurate que hayan {dataAllEmployee.metadata.total_employee} solicitudes aprobadas y/o rechazadas en la plantilla.
              </Typography>
            </Box>
          }
        />
        <Box>
          <UploadExcel
            handleUploadExcel={handleUploadExcel}
            placeholder="Guardar"
            replaceHeaders={["numberRut", "name", "type", "approved"]}
            validateHeaders={["RUT", "Nombre", "Tipo", "Aprobado/Rechazado"]}
            onErrorHeaders={onErrorHeaders}
            handleSendExcel={handleSendData}
            maxLength={dataAllEmployee.metadata.total_employee}
            isOpen={isOpen}
            onClose={handleCloseModal}
          />
        </Box>
        <Alert
          open={alert?.open}
          handleConfirmation={handleConfirmationAlert}
          title={alert?.title}
          messages={alert?.messages}
          icon={alert?.icon}
        />
      </PaperWhite>
    </Stack>
  );
}
