import { Box, Button, Stack, Typography } from "@mui/material";
import { PaperWhite } from "../../../../components/Papers";
import completeImage from "../../../assets/images/complete.png"

export const UploadFinish = ({ goBackTitleButton, goBack}) => {
  return (
    <>
      <PaperWhite >
        <Box
          alignSelf='center'
          component='img'
          alt="Postulación finalizada"
          src={completeImage}
          sx={{
              display: "block"
          }}
          width={"134.41px"}
          height={"133.75px"}
          mx="auto"
          my={2}
        />
        <Typography fontFamily={"Montserrat"} fontWeight={"800"} fontSize={"24px"} lineHeight={"29.26px"} color="#212121" align="center">
          {"!Buen trabajo!"}
        </Typography>
        <Typography fontFamily={"Lato"} fontWeight={"800"} fontSize={"16px"} lineHeight={"21.6px"} color="#212121" align="center">
          {`El archivo cargado se ha procesado con éxito.`}
          <br/>
          {`Puedes revisar si hay nuevas solicitudes en "Volver a Carga masiva"`}
        </Typography>
        <Stack
          direction='row'
          justifyContent='center'
          paddingY={2}
          spacing={10}
        >
          <Button
              onClick={() => goBack()}
              sx={{
                borderRadius:"12px", 
                width:"200px", 
                height:"40px",
                padding:"10px, 16px, 10px, 16px"
              }}
          >
              {goBackTitleButton}
          </Button>
      </Stack>
      </PaperWhite>
    </>
  )
}