import {
  Routes as Router,
  Route,
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";

import { useEffect, useState } from "react";
// import { ListApplications } from '../pages/ListApplications'
// import { Application } from '../pages/ListApplications/Application'
// import { CreateApplication } from '../pages/CreateApplication'
// import { CompleteApplication } from '../pages/CompleteApplication'
// import { MyApplications } from '../pages/MyApplications'
// import { ListProviders } from '../pages/ListProviders'
// import { Provider } from '../pages/ListProviders/Provider'
// import { BlockedListProviders } from '../pages/ListProviders/BlockedListProviders'

import { FormOffer, ListOffers, OfferDetails } from "../pages/Offers";

import { ListEmployee } from "../pages/Employee";

import { CreateProfile } from "../pages/Profile";

import { OfferApply } from "../pages/Apply";

import {
  FeaturesVehicles,
  TypeVehicles,
  FormFeaturesVehicles,
  FormTypeVehicles,
} from "../pages/Vehicles";

import { FormDocumentTypes, Types } from "../pages/Docs";
import { Documents, FormDocuments } from "../pages/Docs";
import { FormTemplates, Templates } from "../pages/Docs";
import { ProvidersRequests } from "../v2/pages/MyRequests";

// import {
//   StepsApply,
//   FormStepsApply,
//   StandardAnswer,
//   FormStandardAnswer,
//   StatusSteps,
//   FormStatusSteps,
// } from '../pages/Applications'

import { Bases, FormBases } from "../pages/Bases";

// import {
//   CourierDetails,
//   ListCouriers,
//   BlockedListCouriers,
//   MyCouriersList,
//   MyCourierDetails,
// } from '../pages/Couriers'

// import {
//   FleetDetails,
//   ListFleets,
//   BlockedListFleets,
//   MyFleetsList,
//   MyFleetsDetails,
// } from '../pages/Fleets'

import { Error404 } from "../pages/404";
// import { Test } from '../pages/test'

// import { MyData } from '../pages/MyData'

import { ProtectedRoute } from "../components/ProtectedRoute";
import {
  OFFER_LIST,
  OFFER_READ,
  OFFER_ADD,
  OFFER_UPDATE,
  APPLIES_LIST,
  APPLIES_LIST_SECURITY,
  // APPLIES_READ,
  // APPLY_PROVIDER_ADD,
  APPLY_PROVIDER_LIST,
  //COURIERS_ADD,
  COURIERS_LIST,
  DOCUMENTS_LIST,
  DOCUMENTS_ADD,
  DOCUMENTS_UPDATE,
  // GROUPS_LIST,
  // GROUPS_ADD,
  // GROUPS_UPDATE,
  TEMPLATES_LIST,
  TEMPLATES_ADD,
  TEMPLATES_UPDATE,
  // PROVIDER_ADD,
  PROVIDER_LIST,
  // PROVIDER_READ,
  FEATURES_LIST,
  FEATURES_ADD,
  FEATURES_UPDATE,
  TYPEVEHICLE_LIST,
  TYPEVEHICLE_ADD,
  TYPEVEHICLE_UPDATE,
  // BRANDS_LIST,
  // BRANDS_ADD,
  // BRANDS_UPDATE,
  // MODELS_LIST,
  // MODELS_ADD,
  // MODELS_UPDATE,
  BASES_LIST,
  BASES_ADD,
  BASES_UPDATE,
  // STEPS_LIST,
  // STEPS_ADD,
  // STEPS_UPDATE,
  // STATUS_LIST,
  // STATUS_ADD,
  // STATUS_UPDATE,
  // STANDARD_ANSWER_LIST,
  // STANDARD_ANSWER_ADD,
  // STANDARD_ANSWER_UPDATE,
  // COURIERS_LIST,
  FLEETS_LIST,
  // APPLY_PROVIDER_UPDATE,
  // MY_COURIERS_LIST,
  MY_DATA_PROVIDER,
  // MY_FLEETS_LIST,
  // MY_FLEETS_DETAIL,
  // APPLY_PROVIDER_DETAIL,
  // MY_COURIERS_DETAIL,
  // REPORTS_APPLIES_OFFER,
  // REPORTS_DOCUMENTS_EXPIRED,
} from "../constants/functionalities";
import { useMyProvider } from "../services/hooks/Providers/useMyProvider";
import { NAV_ALLOWED_ROLES, ROLES } from "../constants/roles";
import { includesSome } from "../utils";
import { ENTITY_TYPES } from "../constants/entityTypes";
import { Profile } from "../pages/Profile/Profile";
import { useAuth } from "../hook/useAuth";
import { NewFleetStep } from "../components/Forms/Fleet";
import { FinishApply } from "../components/Forms/Finish";
import ApplyOutlet from "../v2/components/Outlet/ApplyOutlet";
import { RejectedApply } from "../v2/pages/RejectedApply";
import ManageEmployee from "../pages/Employee/ManageEmployee";
import ListApply from "../v2/pages/AppliesList";
import Security from "../v2/pages/Security";
import SecurityApplicationsMassive from "../v2/pages/Security/applicationMassive";
import {
  ProviderFleetList,
  ProviderCouriersList,
  ProviderPeonetasList,
  ProviderEmployeeEdit,
} from "../v2/pages/ProviderList";
import {
  CourierList,
  PeonetaList,
  ProviderList,
  EmployeeEdit,
} from "../v2/pages/Employee"
import { FleetList } from "../v2/pages/Fleet";
import ReplaceFleet from "../v2/pages/Fleet/ReplaceFleet";
import ReplaceEmployee from "../v2/pages/Employee/ReplaceEmployee";
import { RequestsBackoffice } from "../v2/pages/Requests";
import { Replace } from "../v2/pages/Requests/Replace";
import { ReplaceDocument } from "../v2/pages/Requests/ReplaceDocument";
import { RequestsProvider } from '../v2/pages/Requests';
import SecurityRequests from "../v2/pages/Security/requests/SecurityRequests";
// import { Security } from '../pages/Security'
// import { MassiveLoad } from '../pages/Security/massiveLoad'
// import { MyApplication } from '../pages/ListApplications/MyApplication'
// import { AppliesByOffers, ExpiredDocs } from '../pages/Reports'

export function Routes(params) {
  const { nav } = params;
  const [startNavigate, setStartNavigate] = useState(false);
  const navigate = useNavigate();
  const { state } = useLocation();
  const { authorization, email: providerEmail } = useAuth();

  const service = authorization?.find((e) => e.service === "tms");
  const isRoleAllowed = includesSome(NAV_ALLOWED_ROLES, service?.roles ?? []);
  const ownProviderRequest = providerEmail
    ? useMyProvider(providerEmail)
    : null;

  useEffect(() => {
    if (startNavigate || ownProviderRequest?.isLoading) {
      return;
    }

    const employeeType = ownProviderRequest?.data?.type;
    const isProviderType = employeeType === ENTITY_TYPES.PROVIDER;
    const isProviderRole = service?.roles.includes(ROLES.PROVIDER_TMS);
    const hasProviderData = Boolean(ownProviderRequest?.data);
    const isProviderProfile = Boolean(
      isProviderRole && isProviderType && hasProviderData
    );

    if (!isRoleAllowed && !isProviderRole) {
      navigate("/404");
      setStartNavigate(true);
      return;
    }

    if (isRoleAllowed || isProviderProfile) {
      navigate(`/${nav}`, {
        state: {
          fromButton: true,
          nav,
        },
      });
      setStartNavigate(true);
      return;
    }

    if (isProviderRole && !hasProviderData) {
      const completeProfile = "profile/edit";
      const redirection = nav.includes(completeProfile)
        ? nav.replace(completeProfile, "")
        : `?navigate=${nav || "/"}`;
      navigate(`${completeProfile}${redirection}`, {
        state: {
          fromButton: true,
          nav: redirection.replace("?navigate=", ""),
        },
      });
      setStartNavigate(true);
      return;
    }

    navigate("/404");
    setStartNavigate(true);
  }, [nav, ownProviderRequest]);

  if (!startNavigate) {
    return;
  }

  return (
    <Router>
      <Route index element={<div />} />

      <Route path="offers" element={<Outlet />}>
        <Route
          path="list"
          element={
            <ProtectedRoute
              functionality={[OFFER_LIST]}
              outlet={<ListOffers />}
            />
          }
        />
        <Route
          path=":offerId"
          element={
            <ProtectedRoute
              functionality={[OFFER_READ]}
              outlet={<OfferDetails fromButton={state?.fromButton} />}
            />
          }
        />
        <Route
          path="create"
          element={
            <ProtectedRoute
              functionality={[OFFER_ADD]}
              outlet={<FormOffer fromButton={state?.fromButton} />}
            />
          }
        />
        <Route
          path="update/:offerId"
          element={
            <ProtectedRoute
              functionality={[OFFER_UPDATE]}
              outlet={<FormOffer fromButton={state?.fromButton} />}
            />
          }
        />
      </Route>

      <Route path="profile" element={<Outlet />}>
        <Route
          path="my-data"
          element={
            <ProtectedRoute
              functionality={[MY_DATA_PROVIDER]}
              outlet={<Profile />}
            />
          }
        />
        <Route
          path="edit"
          element={
            <ProtectedRoute
              functionality={[MY_DATA_PROVIDER]}
              outlet={<CreateProfile />}
            />
          }
        />
      </Route>

      <Route
        path="/featuresVehicles"
        element={
          <ProtectedRoute
            functionality={[FEATURES_LIST]}
            outlet={<FeaturesVehicles />}
          />
        }
      />

      <Route
        path="/featuresVehicles/create"
        element={
          <ProtectedRoute
            functionality={[FEATURES_ADD]}
            outlet={<FormFeaturesVehicles fromButton={state?.fromButton} />}
          />
        }
      />

      <Route
        path="/featuresVehicles/update/:featureId"
        element={
          <ProtectedRoute
            functionality={[FEATURES_UPDATE]}
            outlet={<FormFeaturesVehicles fromButton={state?.fromButton} />}
          />
        }
      />

      <Route
        path="/typeVehicles"
        element={
          <ProtectedRoute
            functionality={[TYPEVEHICLE_LIST]}
            outlet={<TypeVehicles />}
          />
        }
      />

      <Route
        path="/typeVehicles/create"
        element={
          <ProtectedRoute
            functionality={[TYPEVEHICLE_ADD]}
            outlet={<FormTypeVehicles fromButton={state?.fromButton} />}
          />
        }
      />

      <Route
        path="/typeVehicles/update/:typeId"
        element={
          <ProtectedRoute
            functionality={[TYPEVEHICLE_UPDATE]}
            outlet={<FormTypeVehicles fromButton={state?.fromButton} />}
          />
        }
      />

      <Route
        path="/Bases"
        element={
          <ProtectedRoute functionality={[BASES_LIST]} outlet={<Bases />} />
        }
      />

      <Route
        path="/Bases/create"
        element={
          <ProtectedRoute
            functionality={[BASES_ADD]}
            outlet={<FormBases fromButton={state?.fromButton} />}
            path="/Documents"
            element={
              <ProtectedRoute
                functionality={[DOCUMENTS_LIST]}
                outlet={<Documents />}
              />
            }
          />
        }
      />

      <Route
        path="/Bases/update/:baseId"
        element={
          <ProtectedRoute
            functionality={[BASES_UPDATE]}
            outlet={<FormBases fromButton={state?.fromButton} />}
            path="/Documents/create"
            element={
              <ProtectedRoute
                functionality={[DOCUMENTS_ADD]}
                outlet={<FormDocuments fromButton={state?.fromButton} />}
              />
            }
          />
        }
      />

      <Route
        path="/Documents/update/:documentId"
        element={
          <ProtectedRoute
            functionality={[DOCUMENTS_UPDATE]}
            outlet={<FormDocuments fromButton={state?.fromButton} />}
          />
        }
      />

      <Route
        path="/Types"
        element={
          <ProtectedRoute functionality={[DOCUMENTS_LIST]} outlet={<Types />} />
        }
      />

      <Route
        path="/Types/create"
        element={
          <ProtectedRoute
            functionality={[DOCUMENTS_ADD]}
            outlet={<FormDocumentTypes />}
          />
        }
      />

      <Route
        path="/Types/update/:documentTypeId"
        element={
          <ProtectedRoute
            functionality={[DOCUMENTS_UPDATE]}
            outlet={<FormDocumentTypes />}
          />
        }
      />

      <Route
        path="/Templates"
        element={
          <ProtectedRoute
            functionality={[TEMPLATES_LIST]}
            outlet={<Templates />}
          />
        }
      />

      <Route
        path="/Templates/create"
        element={
          <ProtectedRoute
            functionality={[TEMPLATES_ADD]}
            outlet={<FormTemplates fromButton={state?.fromButton} />}
          />
        }
      />

      <Route
        path="/Templates/update/:templateId"
        element={
          <ProtectedRoute
            functionality={[TEMPLATES_UPDATE]}
            outlet={<FormTemplates fromButton={state?.fromButton} />}
          />
        }
      />

      <Route
        path="/myrequest"
        element={
          <ProtectedRoute
            functionality={[APPLY_PROVIDER_LIST]}
            outlet={<ProvidersRequests />}
          />
        }
      />
      <Route
        path="/offerApplication"
        element={
          <ProtectedRoute
            functionality={[MY_DATA_PROVIDER]}
            outlet={<OfferApply></OfferApply>}
          />
        }
      />
      <Route path="employee" element={<Outlet />}>
        <Route path="list/:step" element={<ListEmployee />} />
        <Route
          path="update/:employeeId"
          element={
            <ProtectedRoute
              functionality={[APPLY_PROVIDER_LIST]}
              outlet={<ManageEmployee fromButton={state?.fromButton} />}
            />
          }
        />
        <Route
          path="replace/:applyId/:employeeId"
          element={
            <ProtectedRoute
              functionality={[APPLY_PROVIDER_LIST]}
              outlet={<ReplaceEmployee />}
            />
          }
        />
      </Route>
      <Route path="vehicle" element={<Outlet />}>
        <Route
          path="list/:step"
          element={
            <ProtectedRoute
              functionality={[APPLY_PROVIDER_LIST]}
              outlet={<NewFleetStep />}
            />
          }
        />
        <Route
          path="replace/:applyId/:fleetId"
          element={
            <ProtectedRoute
              functionality={[APPLY_PROVIDER_LIST]}
              outlet={<ReplaceFleet />}
            />
          }
        />
      </Route>
      <Route path="finish" element={<Outlet />}>
        <Route
          path=":step"
          element={
            <ProtectedRoute
              functionality={[APPLY_PROVIDER_LIST]}
              outlet={<FinishApply />}
            />
          }
        />
      </Route>
      <Route path="application" element={<ApplyOutlet />}>
        <Route
          path=":applyId/employee/:step"
          element={
            <ProtectedRoute
              functionality={[APPLY_PROVIDER_LIST]}
              outlet={<ListEmployee />}
            />
          }
        />
        <Route
          path=":applyId/fleet/:step"
          element={
            <ProtectedRoute
              functionality={[APPLY_PROVIDER_LIST]}
              outlet={<NewFleetStep />}
            />
          }
        />
      </Route>
      <Route
        path="applyNotAllowed"
        element={
          <RejectedApply message={state?.message ?? "Ups hubo un problema"} />
        }
      />

      <Route
        path="/listApplications"
        element={
          <ProtectedRoute
            functionality={[APPLIES_LIST]}
            outlet={<ListApply />}
          />
        }
      />
      <Route
        path="courier"
        element={
          <ProtectedRoute
            functionality={[COURIERS_LIST]}
            outlet={<CourierList/>}
          />
        }
      />

      <Route
        path="peoneta"
        element={
          <ProtectedRoute
            functionality={[COURIERS_LIST]}
            outlet={<PeonetaList/>}
          />
        }
      />

      <Route
        path="provider"
        element={
          <ProtectedRoute
            functionality={[PROVIDER_LIST]}
            outlet={<ProviderList/>}
          />
        }
      />

      <Route
        path="fleet"
        element={
          <ProtectedRoute
            functionality={[FLEETS_LIST]}
            outlet={<FleetList/>}
          />
        }
      />

      <Route
        path="/securityApplications"
        element={
          <ProtectedRoute
            functionality={[APPLIES_LIST_SECURITY]}
            outlet={<Security />}
          />
        }
      />

      <Route
        path="/securityApplicationsMassive"
        element={
          <ProtectedRoute
            functionality={[APPLIES_LIST_SECURITY]}
            outlet={<SecurityApplicationsMassive />}
          />
        }
      />

      <Route
        path="/securityRequests"
        element={
          <ProtectedRoute
            functionality={[APPLIES_LIST_SECURITY]}
            outlet={<SecurityRequests />}
          />
        }
      />

      <Route
        path="myfleetlist"
        element={
          <ProtectedRoute
            functionality={[MY_DATA_PROVIDER]}
            outlet={<ProviderFleetList />}
          />
        }
      />

      <Route
        path="myCouriers"
        element={
          <ProtectedRoute
            functionality={[MY_DATA_PROVIDER]}
            outlet={<ProviderCouriersList />}
          />
        }
      />

      <Route
        path="myPeonetas"
        element={
          <ProtectedRoute
            functionality={[MY_DATA_PROVIDER]}
            outlet={<ProviderPeonetasList />}
          />
        }
      />

      <Route
        path="provider/employee/edit/:employeeId"
        element={
          <ProtectedRoute
            functionality={[MY_DATA_PROVIDER]}
            outlet={<ProviderEmployeeEdit />}
          />
        }
      />

      <Route
        path="employee/edit/:employeeId"
        element={
          <ProtectedRoute
            functionality={[COURIERS_LIST]}
            outlet={<EmployeeEdit />}
          />
        }
      />

      <Route path="requests" element={<Outlet />}>
        <Route
          path="list"
          element={
            <ProtectedRoute
              functionality={[APPLIES_LIST]}
              outlet={<RequestsBackoffice />}
            />
          }
        />
        <Route
          path="replacement/:requestId"
          element={
            <ProtectedRoute
              functionality={[APPLIES_LIST]}
              outlet={<Replace />}
            />
          }
        />
        <Route
          path="replacementdocument/:requestId"
          element={
            <ProtectedRoute
              functionality={[APPLIES_LIST]}
              outlet={<ReplaceDocument />}
            />
          }
        />
        <Route
          path="provider/list"
          element={
            <ProtectedRoute
              functionality={[MY_DATA_PROVIDER]}
              outlet={<RequestsProvider />}
            />
          }
        />
      </Route>

      {/* <Route
        path='/listApplications/:applicationId'
        element={
          <ProtectedRoute
            functionality={[APPLIES_READ]}
            outlet={
              <Application
                fromButton={state?.fromButton}
                oldTabSelected={
                  state?.oldTabSelected ? state.oldTabSelected : '1'
                }
              />
            }
          />
        }
      />

      <Route
        path='/createApplication/:id'
        element={
          <ProtectedRoute
            functionality={[APPLY_PROVIDER_ADD]}
            outlet={<CreateApplication />}
          />
        }
      />

      <Route
        path='/completeApplication/:id'
        element={
          <ProtectedRoute
            functionality={[APPLY_PROVIDER_UPDATE]}
            outlet={
              <CompleteApplication
                offerDetails={state?.offer}
                isFull={state?.isFull}
                stepFlow={state?.stepFlow}
                fromButton={state?.fromButton}
                formProvider={state?.formProvider}
              />
            }
          />
        }
      />

      <Route
        path='/MyApplications'
        element={
          <ProtectedRoute
            functionality={[APPLY_PROVIDER_LIST]}
            outlet={<MyApplications fromMyData='false' />}
          />
        }
      />

      <Route
        path='/MyApplications/:applicationId'
        element={
          <ProtectedRoute
            functionality={[APPLY_PROVIDER_DETAIL]}
            outlet={
              <MyApplication
                fromButton={state?.fromButton}
                oldTabSelected={
                  state?.oldTabSelected ? state.oldTabSelected : '1'
                }
              />
            }
          />
        }
      />

      <Route
        path='/listProviders'
        element={
          <ProtectedRoute
            functionality={[PROVIDER_LIST]}
            outlet={<ListProviders myProvider='' />}
          />
        }
      />

      <Route
        path='/listProviders/blocked'
        element={
          <ProtectedRoute
            functionality={[PROVIDER_LIST]}
            outlet={<BlockedListProviders />}
          />
        }
      />

      <Route
        path='/listProviders/:providerId'
        element={
          <ProtectedRoute
            functionality={[PROVIDER_READ]}
            outlet={<Provider fromButton={state?.fromButton} />}
          />
        }
      />

      <Route path='couriers' element={<Outlet />}>
        <Route
          path='list'
          element={
            <ProtectedRoute
              functionality={[COURIERS_LIST]}
              outlet={<ListCouriers />}
            />
          }
        />
        <Route
          path=':courierId'
          element={
            <ProtectedRoute
              functionality={[COURIERS_LIST]}
              outlet={<CourierDetails fromButton={state?.fromButton} />}
            />
          }
        />
        <Route
          path='list/blocked'
          element={
            <ProtectedRoute
              functionality={[COURIERS_LIST]}
              outlet={<BlockedListCouriers />}
            />
          }
        />
      </Route>

      <Route
        path='myData'
        element={
          <ProtectedRoute
            functionality={[MY_DATA_PROVIDER]}
            outlet={<MyData />}
          />
        }
      />

      <Route path='myCouriers' element={<Outlet />}>
        <Route
          path='list'
          element={
            <ProtectedRoute
              functionality={[MY_COURIERS_LIST]}
              outlet={<MyCouriersList />}
            />
          }
        />
        <Route
          path=':courierId'
          element={
            <ProtectedRoute
              functionality={[MY_COURIERS_DETAIL]}
              outlet={<MyCourierDetails fromButton={state?.fromButton} />}
            />
          }
        />
      </Route>

      <Route path='fleets' element={<Outlet />}>
        <Route
          path='list'
          element={
            <ProtectedRoute
              functionality={[FLEETS_LIST]}
              outlet={<ListFleets providerIdFromMyData='' />}
            />
          }
        />
        <Route
          path=':fleetId'
          element={
            <ProtectedRoute
              functionality={[FLEETS_LIST]}
              outlet={<FleetDetails fromButton={state?.fromButton} />}
            />
          }
        />
        <Route
          path='list/blocked'
          element={
            <ProtectedRoute
              functionality={[FLEETS_LIST]}
              outlet={<BlockedListFleets />}
            />
          }
        />
      </Route>

      <Route
        path='/Brands'
        element={
          <ProtectedRoute functionality={[BRANDS_LIST]} outlet={<Brands />} />
        }
      />

      <Route
        path='/Brands/create'
        element={
          <ProtectedRoute
            functionality={[BRANDS_ADD]}
            outlet={<FormBrands fromButton={state?.fromButton} />}
          />
        }
      />

      <Route
        path='/Brands/update/:brandId'
        element={
          <ProtectedRoute
            functionality={[BRANDS_UPDATE]}
            outlet={<FormBrands fromButton={state?.fromButton} />}
          />
        }
      />

      <Route
        path='/Models'
        element={
          <ProtectedRoute
            functionality={[MODELS_LIST]}
            outlet={<ModelsVehicle />}
          />
        }
      />

      <Route
        path='/Models/create'
        element={
          <ProtectedRoute
            functionality={[MODELS_ADD]}
            outlet={<FormModelsVehicle fromButton={state?.fromButton} />}
          />
        }
      />

      <Route
        path='/Models/update/:modelId'
        element={
          <ProtectedRoute
            functionality={[MODELS_UPDATE]}
            outlet={<FormModelsVehicle fromButton={state?.fromButton} />}
          />
        }
      />

      <Route
        path='/Groups'
        element={
          <ProtectedRoute functionality={[GROUPS_LIST]} outlet={<Groups />} />
        }
      />

      <Route
        path='/Groups/create'
        element={
          <ProtectedRoute
            functionality={[GROUPS_ADD]}
            outlet={<FormGroups fromButton={state?.fromButton} />}
          />
        }
      />

      <Route
        path='/Groups/update/:groupId'
        element={
          <ProtectedRoute
            functionality={[GROUPS_UPDATE]}
            outlet={<FormGroups fromButton={state?.fromButton} />}
          />
        }
      />

      <Route
        path='/StepsApply'
        element={
          <ProtectedRoute
            functionality={[STEPS_LIST]}
            outlet={<StepsApply />}
          />
        }
      />

      <Route
        path='/StepsApply/create'
        element={
          <ProtectedRoute
            functionality={[STEPS_ADD]}
            outlet={<FormStepsApply fromButton={state?.fromButton} />}
          />
        }
      />

      <Route
        path='/StepsApply/update/:stepId'
        element={
          <ProtectedRoute
            functionality={[STEPS_UPDATE]}
            outlet={<FormStepsApply fromButton={state?.fromButton} />}
          />
        }
      />

      <Route
        path='/StatusSteps'
        element={
          <ProtectedRoute
            functionality={[STATUS_LIST]}
            outlet={<StatusSteps />}
          />
        }
      />

      <Route
        path='/StatusSteps/create'
        element={
          <ProtectedRoute
            functionality={[STATUS_ADD]}
            outlet={<FormStatusSteps fromButton={state?.fromButton} />}
          />
        }
      />

      <Route
        path='/StatusSteps/update/:statusId'
        element={
          <ProtectedRoute
            functionality={[STATUS_UPDATE]}
            outlet={<FormStatusSteps fromButton={state?.fromButton} />}
          />
        }
      />

      <Route
        path='/StandardAnswer'
        element={
          <ProtectedRoute
            functionality={[STANDARD_ANSWER_LIST]}
            outlet={<StandardAnswer />}
          />
        }
      />

      <Route
        path='/StandardAnswer/create'
        element={
          <ProtectedRoute
            functionality={[STANDARD_ANSWER_ADD]}
            outlet={<FormStandardAnswer fromButton={state?.fromButton} />}
          />
        }
      />

      <Route
        path='/StandardAnswer/update/:standardId'
        element={
          <ProtectedRoute
            functionality={[STANDARD_ANSWER_UPDATE]}
            outlet={<FormStandardAnswer fromButton={state?.fromButton} />}
          />
        }
      />
      */}

      <Route path="/404" element={<Error404 />} />
      {/* <Route path='/test' element={<Test />} />

      <Route path='myFleets' element={<Outlet />}>
        <Route
          path='list'
          element={
            <ProtectedRoute
              functionality={[MY_FLEETS_LIST]}
              outlet={<MyFleetsList />}
            />
          }
        />
        <Route
          path=':fleetId'
          element={
            <ProtectedRoute
              functionality={[MY_FLEETS_DETAIL]}
              outlet={<MyFleetsDetails fromButton={state?.fromButton} />}
            />
          }
        />
      </Route>

      <Route path='reports' element={<Outlet />}>
        <Route
          path='appliesByOffers'
          element={
            <ProtectedRoute
              functionality={[REPORTS_APPLIES_OFFER]}
              outlet={<AppliesByOffers />}
            />
          }
        />
        <Route
          path='expiredDocuments'
          element={
            <ProtectedRoute
              functionality={[REPORTS_DOCUMENTS_EXPIRED]}
              outlet={<ExpiredDocs />}
            />
          }
        />
      </Route> */}
    </Router>
  );
}
