import { useEffect, useState } from "react";
import {
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Collapse,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as AvailableOffer } from "../../v2/assets/images/availableOffer.svg";
import { ReactComponent as Applications } from "../../v2/assets/images/applications.svg";
import { ReactComponent as Requests } from "../../v2/assets/images/requests.svg";
import { ReactComponent as ProviderProfile } from "../../v2/assets/images/providerProfile.svg";
import { ReactComponent as Fleet } from "../../v2/assets/images/fleet.svg";
import { ReactComponent as Courier } from "../../v2/assets/images/courier.svg";
import { ReactComponent as Peoneta } from "../../v2/assets/images/peoneta.svg";
import { ReactComponent as Offer } from "../../v2/assets/images/offer.svg";
import { ReactComponent as Setting } from "../../v2/assets/images/setting.svg";
import { ReactComponent as Security } from "../../v2/assets/images/security.svg";

import { ProtectedComponent } from "../ProtectedComponent";
import {
  OFFER_LIST,
  APPLIES_LIST,
  FEATURES_LIST,
  TYPEVEHICLE_LIST,
  DOCUMENTS_LIST,
  TEMPLATES_LIST,
  BASES_LIST,
  APPLY_PROVIDER_LIST,
  MY_DATA_PROVIDER,
  APPLIES_LIST_SECURITY,
  COURIERS_LIST,
  FLEETS_LIST,
} from "../../constants/functionalities";

const nestedLists = {
  featuresVehicles: ["configuration", "vehicles"],
  typeVehicles: ["configuration", "vehicles"],
  Types: ["configuration", "docs"],
  Templates: ["configuration", "docs"],
  Bases: ["configuration", "general"],
  securityApplications: ["applicationsSecurity"],
  securityApplicationsMassive: ["applicationsSecurity"],
};

export function Sidebar() {
  const location = useLocation();
  const [open, setOpen] = useState({});
  const [isActive, setIsActive] = useState(null);

  const handleOpenMenu = (value) => {
    setOpen({ ...open, [value]: !open[value] });
  };

  const isPathActive = () => {
    const path = location.pathname.split("/");
    const active = path[1];

    nestedLists[isActive]?.forEach((item) =>
      setOpen((old) => ({ ...old, [item]: true }))
    );

    setIsActive(active);
  };

  useEffect(() => {
    isPathActive();
  }, [location]);

  const openNewTab = (url) => {
    window.open(url, "_blank");
  };

  return (
    <List component="nav">
      {/*------ PROVEEDOR ------*/}
      <ProtectedComponent functionality={[MY_DATA_PROVIDER]}>
        <ListItemButton
          component={Link}
          selected={isActive === "availableOffers"}
          onClick={() => {
            openNewTab(process.env.REACT_APP_PUBLIC_URL);
            return null;
          }}
          sx={{ color: isActive === "availableOffers" && "lightBluePrimary" }}
        >
          <ListItemIcon>
            <AvailableOffer />
          </ListItemIcon>
          <ListItemText
            primary="Ofertas disponibles"
            sx={{
              "& span": {
                fontFamily: "Montserrat",
                fontWeight: 700,
                fontSize: "14px",
              },
            }}
          />
        </ListItemButton>
      </ProtectedComponent>

      <ProtectedComponent functionality={[APPLY_PROVIDER_LIST]}>
        <ListItemButton
          component={Link}
          to="/myrequest"
          selected={isActive === "myrequest"}
          sx={{ color: isActive === "myrequest" && "lightBluePrimary" }}
        >
          <ListItemIcon>
            <Applications />
          </ListItemIcon>
          <ListItemText
            primary="Mis postulaciones"
            sx={{
              "& span": {
                fontFamily: "Montserrat",
                fontWeight: 700,
                fontSize: "14px",
              },
            }}
          />
        </ListItemButton>
      </ProtectedComponent>

      <ProtectedComponent functionality={[MY_DATA_PROVIDER]}>
        <ListItemButton
          component={Link}
          to="/requests/provider/list"
          selected={isActive === "requests"}
          sx={{ color: isActive === "requests" && "lightBluePrimary" }}
        >
          <ListItemIcon>
            <Requests />
          </ListItemIcon>
          <ListItemText
            primary="Mis solicitudes"
            sx={{
              "& span": {
                fontFamily: "Montserrat",
                fontWeight: 700,
                fontSize: "14px",
              },
            }}
          />
        </ListItemButton>
      </ProtectedComponent>

      <ProtectedComponent functionality={[MY_DATA_PROVIDER]}>
        <ListItemButton
          component={Link}
          to="/profile/my-data"
          selected={isActive === "profile"}
          sx={{ color: isActive === "profile" && "lightBluePrimary" }}
        >
          <ListItemIcon>
            <ProviderProfile />
          </ListItemIcon>
          <ListItemText
            primary="Perfil proveedor"
            sx={{
              "& span": {
                fontFamily: "Montserrat",
                fontWeight: 700,
                fontSize: "14px",
              },
            }}
          />
        </ListItemButton>
      </ProtectedComponent>

      <ProtectedComponent functionality={[MY_DATA_PROVIDER]}>
        <ListItemButton
          component={Link}
          to="/myfleetlist"
          selected={isActive === "myfleetlist"}
          sx={{ color: isActive === "myfleetlist" && "lightBluePrimary" }}
        >
          <ListItemIcon>
            <Fleet />
          </ListItemIcon>
          <ListItemText
            primary="Mis vehículos"
            sx={{
              "& span": {
                fontFamily: "Montserrat",
                fontWeight: 700,
                fontSize: "14px",
              },
            }}
          />
        </ListItemButton>
      </ProtectedComponent>

      <ProtectedComponent functionality={[MY_DATA_PROVIDER]}>
        <ListItemButton
          component={Link}
          to="/myCouriers"
          selected={isActive === "myCouriers"}
          sx={{ color: isActive === "myCouriers" && "lightBluePrimary" }}
        >
          <ListItemIcon>
            <Courier />
          </ListItemIcon>
          <ListItemText
            primary="Mis conductores"
            sx={{
              "& span": {
                fontFamily: "Montserrat",
                fontWeight: 700,
                fontSize: "14px",
              },
            }}
          />
        </ListItemButton>
      </ProtectedComponent>

      <ProtectedComponent functionality={[MY_DATA_PROVIDER]}>
        <ListItemButton
          component={Link}
          to="/myPeonetas"
          selected={isActive === "myPeonetas"}
          sx={{ color: isActive === "myPeonetas" && "lightBluePrimary" }}
        >
          <ListItemIcon>
            <Peoneta />
          </ListItemIcon>
          <ListItemText
            primary="Mis peonetas"
            sx={{
              "& span": {
                fontFamily: "Montserrat",
                fontWeight: 700,
                fontSize: "14px",
              },
            }}
          />
        </ListItemButton>
      </ProtectedComponent>

      {/*------ BACKOFFICE ------*/}

      <ProtectedComponent functionality={[OFFER_LIST]}>
        <ListItemButton
          component={Link}
          to="/offers/list"
          selected={isActive === "offers"}
          sx={{ color: isActive === "offers" && "lightBluePrimary" }}
        >
          <ListItemIcon>
            <Offer />
          </ListItemIcon>

          <ListItemText
            primary="Ofertas"
            sx={{
              "& span": {
                fontFamily: "Montserrat",
                fontWeight: 700,
                fontSize: "14px",
              },
            }}
          />
        </ListItemButton>
      </ProtectedComponent>

      <ProtectedComponent functionality={[APPLIES_LIST]}>
        <ListItemButton
          component={Link}
          to="/listApplications"
          selected={isActive === "listApplications"}
          sx={{ color: isActive === "listApplications" && "lightBluePrimary" }}
        >
          <ListItemIcon>
            <Applications />
          </ListItemIcon>
          <ListItemText
            primary="Postulaciones"
            sx={{
              "& span": {
                fontFamily: "Montserrat",
                fontWeight: 700,
                fontSize: "14px",
              },
            }}
          />
        </ListItemButton>
      </ProtectedComponent>

      <ProtectedComponent functionality={[APPLIES_LIST]}>
        <ListItemButton
          component={Link}
          to="/requests/list"
          selected={isActive === "requests"}
          sx={{ color: isActive === "requests" && "lightBluePrimary" }}
        >
          <ListItemIcon>
            <Requests />
          </ListItemIcon>
          <ListItemText
            primary="Solicitudes"
            sx={{
              "& span": {
                fontFamily: "Montserrat",
                fontWeight: 700,
                fontSize: "14px",
              },
            }}
          />
        </ListItemButton>
      </ProtectedComponent>

      <ProtectedComponent functionality={[FEATURES_LIST, TYPEVEHICLE_LIST]}>
        <ListItemButton onClick={() => handleOpenMenu("configuration")}>
          <ListItemIcon>
            <Setting />
          </ListItemIcon>
          <ListItemText
            primary="Configuración"
            sx={{
              "& span": {
                fontFamily: "Montserrat",
                fontWeight: 700,
                fontSize: "14px",
              },
            }}
          />
          {open.configuration ? (
            <ExpandLess color="secondary" />
          ) : (
            <ExpandMore color="secondary" />
          )}
        </ListItemButton>
        <Collapse in={open.configuration} timeout="auto" unmountOnExit>
          <ProtectedComponent functionality={[FEATURES_LIST, TYPEVEHICLE_LIST]}>
            <ListItemButton onClick={() => handleOpenMenu("vehicles")}>
              <ListItemIcon />
              <ListItemText
                primary="Vehículos"
                sx={{
                  "& span": {
                    fontFamily: "Montserrat",
                    fontWeight: 700,
                    fontSize: 14,
                  },
                }}
              />
              {open.vehicles ? (
                <ExpandLess color="secondary" />
              ) : (
                <ExpandMore color="secondary" />
              )}
            </ListItemButton>
            <Collapse in={open.vehicles} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ProtectedComponent functionality={[FEATURES_LIST]}>
                  <ListItemButton
                    component={Link}
                    to="/featuresVehicles"
                    selected={isActive === "featuresVehicles"}
                    sx={{
                      pl: 4,
                      color:
                        isActive === "featuresVehicles" && "lightBluePrimary",
                    }}
                  >
                    <ListItemIcon />
                    <ListItemText
                      sx={{ "& span": { fontSize: 14 } }}
                      primary="Características de vehículos"
                    />
                  </ListItemButton>
                </ProtectedComponent>
                <ProtectedComponent functionality={[TYPEVEHICLE_LIST]}>
                  <ListItemButton
                    component={Link}
                    to="/typeVehicles"
                    selected={isActive === "typeVehicles"}
                    sx={{
                      pl: 4,
                      color: isActive === "typeVehicles" && "lightBluePrimary",
                    }}
                  >
                    <ListItemIcon />
                    <ListItemText
                      sx={{ "& span": { fontSize: 14 } }}
                      primary="Tipo de vehículos"
                    />
                  </ListItemButton>
                </ProtectedComponent>
              </List>
            </Collapse>
          </ProtectedComponent>

          <ProtectedComponent functionality={[DOCUMENTS_LIST]}>
            <ListItemButton onClick={() => handleOpenMenu("docs")}>
              <ListItemIcon />
              <ListItemText
                primary="Gestión de documentos"
                sx={{
                  "& span": {
                    fontFamily: "Montserrat",
                    fontWeight: 700,
                    fontSize: 14,
                  },
                }}
              />
              {open["docs"] ? (
                <ExpandLess color="secondary" />
              ) : (
                <ExpandMore color="secondary" />
              )}
            </ListItemButton>
            <Collapse in={open["docs"]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ProtectedComponent functionality={[DOCUMENTS_LIST]}>
                  <ListItemButton
                    component={Link}
                    to="/Types"
                    selected={isActive === "Types"}
                    sx={{
                      pl: 4,
                      color: isActive === "Types" && "lightBluePrimary",
                    }}
                  >
                    <ListItemIcon />
                    <ListItemText
                      sx={{ "& span": { fontSize: 14 } }}
                      primary="Tipos de documentos"
                    />
                  </ListItemButton>
                </ProtectedComponent>
                <ProtectedComponent functionality={[TEMPLATES_LIST]}>
                  <ListItemButton
                    component={Link}
                    to="/Templates"
                    selected={isActive === "Templates"}
                    sx={{
                      pl: 4,
                      color: isActive === "Templates" && "lightBluePrimary",
                    }}
                  >
                    <ListItemIcon />
                    <ListItemText
                      sx={{ "& span": { fontSize: 14 } }}
                      primary="Plantilla"
                    />
                  </ListItemButton>
                </ProtectedComponent>
              </List>
            </Collapse>
          </ProtectedComponent>
          <ProtectedComponent functionality={[BASES_LIST]}>
            <ListItemButton onClick={() => handleOpenMenu("general")}>
              <ListItemIcon />
              <ListItemText
                primary="General"
                sx={{
                  "& span": {
                    fontFamily: "Montserrat",
                    fontWeight: 700,
                    fontSize: 14,
                  },
                }}
              />
              {open["general"] ? (
                <ExpandLess color="secondary" />
              ) : (
                <ExpandMore color="secondary" />
              )}
            </ListItemButton>
            <Collapse in={open["general"]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ProtectedComponent functionality={[BASES_LIST]}>
                  <ListItemButton
                    component={Link}
                    to="/Bases"
                    selected={isActive === "Bases"}
                    sx={{
                      pl: 4,
                      color: isActive === "Bases" && "lightBluePrimary",
                    }}
                  >
                    <ListItemIcon />
                    <ListItemText
                      sx={{ "& span": { fontSize: 14 } }}
                      primary="Bases"
                    />
                  </ListItemButton>
                </ProtectedComponent>
              </List>
            </Collapse>
          </ProtectedComponent>
        </Collapse>
      </ProtectedComponent>

      <ProtectedComponent functionality={[COURIERS_LIST]}>
        <ListItemButton
          component={Link}
          to="/provider"
          selected={isActive === "provider"}
          sx={{ color: isActive === "provider" && "lightBluePrimary" }}
        >
          <ListItemIcon>
            <ProviderProfile />
          </ListItemIcon>
          <ListItemText
            primary="Proveedores"
            sx={{
              "& span": {
                fontFamily: "Montserrat",
                fontWeight: 700,
                fontSize: "14px",
              },
            }}
          />
        </ListItemButton>
      </ProtectedComponent>

      <ProtectedComponent functionality={[COURIERS_LIST]}>
        <ListItemButton
          component={Link}
          to="/courier"
          selected={isActive === "courier"}
          sx={{ color: isActive === "courier" && "lightBluePrimary" }}
        >
          <ListItemIcon>
            <Courier />
          </ListItemIcon>
          <ListItemText
            primary="Conductores"
            sx={{
              "& span": {
                fontFamily: "Montserrat",
                fontWeight: 700,
                fontSize: "14px",
              },
            }}
          />
        </ListItemButton>
      </ProtectedComponent>

      <ProtectedComponent functionality={[COURIERS_LIST]}>
        <ListItemButton
          component={Link}
          to="/peoneta"
          selected={isActive === "peoneta"}
          sx={{ color: isActive === "peoneta" && "lightBluePrimary" }}
        >
          <ListItemIcon>
            <Peoneta />
          </ListItemIcon>
          <ListItemText
            primary="Peonetas"
            sx={{
              "& span": {
                fontFamily: "Montserrat",
                fontWeight: 700,
                fontSize: "14px",
              },
            }}
          />
        </ListItemButton>
      </ProtectedComponent>

      <ProtectedComponent functionality={[FLEETS_LIST]}>
        <ListItemButton
          component={Link}
          to="/fleet"
          selected={isActive === "fleet"}
          sx={{ color: isActive === "fleet" && "lightBluePrimary" }}
        >
          <ListItemIcon>
            <Fleet />
          </ListItemIcon>
          <ListItemText
            primary="Vehículos"
            sx={{
              "& span": {
                fontFamily: "Montserrat",
                fontWeight: 700,
                fontSize: "14px",
              },
            }}
          />
        </ListItemButton>
      </ProtectedComponent>

      {/*------ SECURITY ------*/}

      <ProtectedComponent functionality={[APPLIES_LIST_SECURITY]}>
        <ListItemButton onClick={() => handleOpenMenu("applicationsSecurity")}>
          <ListItemIcon>
            <Security />
          </ListItemIcon>
          <ListItemText
            primary="Postulaciones"
            sx={{
              "& span": {
                fontFamily: "Montserrat",
                fontWeight: 700,
                fontSize: "14px",
              },
            }}
          />
          {open.applicationsSecurity ? (
            <ExpandLess color="secondary" />
          ) : (
            <ExpandMore color="secondary" />
          )}
        </ListItemButton>
        <Collapse in={open.applicationsSecurity} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ProtectedComponent functionality={[APPLIES_LIST_SECURITY]}>
              <ListItemButton
                component={Link}
                to="/securityApplications"
                selected={isActive === "securityApplications"}
                sx={{
                  pl: 4,
                  color:
                    isActive === "securityApplications" && "lightBluePrimary",
                }}
              >
                <ListItemIcon />
                <ListItemText
                  sx={{ "& span": { fontSize: 14 } }}
                  primary="Unitario"
                />
              </ListItemButton>
            </ProtectedComponent>
            {/* <ProtectedComponent functionality={[APPLIES_LIST_SECURITY]}>
              <ListItemButton
                component={Link}
                to="/securityApplicationsMassive"
                selected={isActive === "securityApplicationsMassive"}
                sx={{ pl: 4, color: isActive === "securityApplicationsMassive" && "lightBluePrimary" }}
              >
                <ListItemIcon />
                <ListItemText
                  sx={{ "& span": { fontSize: 14 } }}
                  primary="Carga masiva"
                />
              </ListItemButton>
            </ProtectedComponent> */}
          </List>
        </Collapse>
      </ProtectedComponent>
      <ProtectedComponent functionality={[APPLIES_LIST_SECURITY]}>
        <ListItemButton
          component={Link}
          to="/securityRequests"
          selected={isActive === "securityRequests"}
          sx={{ color: isActive === "securityRequests" && "lightBluePrimary" }}
          onClick={() => handleOpenMenu("securityRequests")}
        >
          <ListItemIcon>
            <Requests />
          </ListItemIcon>
          <ListItemText
            primary="Solicitudes"
            sx={{
              "& span": {
                fontFamily: "Montserrat",
                fontWeight: 700,
                fontSize: "14px",
              },
            }}
          />
        </ListItemButton>
      </ProtectedComponent>
    </List>
  );
}
